import classNames from 'classnames'
import ClientsCarrousel from 'components/Atoms/ClientsCarrousel'
import DemoCard from 'components/Atoms/DemoCard'
import PartnersCarrousel from 'components/Atoms/PartnersCarrousel'
import RichText from 'components/Atoms/RichText'
import Benefits from 'components/Molecules/Benefits'
import Benefits2 from 'components/Molecules/Benefits2'
import FirstFrameCaes from 'components/Molecules/FirstFrameCaes'
import MainSectionCaes from 'components/Molecules/MainSectionCaes'
import SecondFrameCaes from 'components/Molecules/SecondFrameCaes'
import SecondSectionCaes from 'components/Molecules/SecondSectionCaes'
import Testimonials from 'components/Molecules/Testimonials'
import Footer from 'components/Organisms/Footer'
import { useTranslation } from 'react-i18next'
import { testimonialsCAEs } from 'utilities'

export default function Caes() {
  const { t } = useTranslation('caes')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'px-4',
    'md:px-28',
    'relative',
    'z-10',
  )
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-lg',
    'sm:text-2xl',
    'font-semibold',
  )
  const textClassName = classNames('text-gray-900', 'text-lg')
  return (
    <div className="tracking-tight space-y-10">
      <MainSectionCaes />
      <div className={bodyClassname}>
        <FirstFrameCaes />
        <Benefits />

        <h2 className="relative z-10 text-center uppercase font-semibold text-blue-800 sm:text-xl">
          {t('successStories')}
        </h2>
        <ClientsCarrousel />
        <Testimonials testimonials={testimonialsCAEs} />
      </div>
      <SecondFrameCaes />
      <div className={bodyClassname}>
        <div className="relative z-10 flex flex-col lg:flex-row items-center md:space-x-24">
          <div className="basis-full sm:basis-1/2 flex justify-center items-center">
            <img
              className="w-4/5 sm:w-full"
              src={`${process.env.REACT_APP_URL_S3}/images/law-caes-image.png`}
              alt="mainImage"
            />
          </div>
          <div className="basis-full sm:basis-1/2 p-4 sm:p-0 space-y-4 sm:mt-0">
            <h3 className={subtitleClassName}>{t('titleLaw')}</h3>
            <RichText
              className={textClassName}
              highlightClassName="text-blue-800"
              content={t('textLaw')}
            />
          </div>
        </div>
      </div>
      <Benefits2 />
      <div className="relative w-full h-full">
        <div className={bodyClassname}>
          <SecondSectionCaes />
          <DemoCard />
        </div>
      </div>
      <PartnersCarrousel />
      <Footer />
    </div>
  )
}
