import { ButtonVariants } from '../../constants'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import ContactFrame from 'components/Atoms/ContactFrame'
import { useTranslation } from 'react-i18next'

export default function SecondFrameCaes() {
  const { t } = useTranslation('caes')
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-lg',
    'sm:text-2xl',
    'font-semibold',
  )
  return (
    <ContactFrame
      className="mt-10 items-center bg-gradient-to-r from-black to-blue-800 z-50"
      img={`${process.env.REACT_APP_URL_S3}/images/secondFrame-caes-image.png`}
    >
      <div className="flex flex-col space-y-6 md:w-1/3 2xl:w-1/4 text-white text-center md:text-start items-center md:items-start">
        <h3 className={subtitleClassName}>{t('secondFrame.title')}</h3>
        <span>{t('secondFrame.text')}</span>
        <a
          href="https://landing.ciclogreen.com/es/es/es/certificados-ahorro-energetico-info"
          target="_blank"
          rel="noreferrer noopener"
        >
          <RoundedButton variant={ButtonVariants.TERTIARY}>
            {t('btn.download')}
          </RoundedButton>
        </a>
      </div>
    </ContactFrame>
  )
}
