import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import { useTranslation } from 'react-i18next'
import { benefitsMock2 } from 'utilities'

export default function Benefits2() {
  const { t } = useTranslation('caes')
  const bigImageClass = classNames(
    'z-0',
    'absolute',
    'top-20',
    'right-0',
    'w-screen',
    'h-full',
    'sm:h-auto',
    'object-cover',
  )
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'px-4',
    'md:px-28',
    'relative',
    'z-10',
  )
  const gridClassName = classNames(
    'grid',
    'grid-cols-1',
    'sm:grid-cols-2',
    'lg:grid-cols-4',
    'gap-10',
    'w-full',
    'items-start',
    'justify-center',
    'justify-items-center',
  )
  return (
    <div className="relative w-full h-full" id="benefits">
      <img
        className={bigImageClass}
        src={`${process.env.REACT_APP_URL_S3}/images/bg3.png`}
        alt="layerBackground"
      />
      <div className={bodyClassname}>
        <div className="relative z-10 pt-6 space-y-10 px-4 lg:px-28 text-center">
          <h2 className="text-2xl sm:text-4xl font-semibold text-black">
            {t('benefits2.title')}
          </h2>
        </div>
        <div className={gridClassName}>
          {benefitsMock2.map((element, index) => {
            return (
              <div
                key={index}
                className="flex flex-col space-y-4 items-center w-4/5 sm:w-full"
              >
                <img
                  src={`${process.env.REACT_APP_URL_S3}/images/${element}-caes-image.png`}
                  alt={element}
                  className="h-full sm:h-48"
                />
                <p className="text-center text-gray-900">
                  {t(`benefits2.${element}`)}
                </p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="relative z-10 w-full text-center mt-10">
        <a
          href="https://app.hubspot.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=4957790-CAE&utm_source=LP&utm_medium=boton&utm_content=solicitar-demo"
          target="_blank"
          rel="noreferrer noopener"
        >
          <RoundedButton>{t('btn.askForDemo')}</RoundedButton>
        </a>
      </div>
    </div>
  )
}
