import classNames from 'classnames'
import BenefitCard from 'components/Atoms/BenefitCard'
import { useTranslation } from 'react-i18next'
import { benefitsMock } from 'utilities'

const Benefits = () => {
  const { t } = useTranslation('caes')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'relative',
    'z-10',
    'mt-10',
  )
  const benefitsClassName = classNames(
    'grid',
    'grid-cols-1',
    'lg:grid-cols-4',
    'gap-10',
    'w-full',
    'items-center',
    'justify-center',
    'max-sm:px-6',
  )
  return (
    <div className={bodyClassname} id="benefits">
      <h3 className="relative z-10 text-center font-semibold sm:text-4xl text-black">
        {t('benefits.title')
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
      </h3>
      <div className={benefitsClassName}>
        {benefitsMock.map((benefit, index) => (
          <BenefitCard benefit={benefit} key={index} />
        ))}
      </div>
    </div>
  )
}

export default Benefits
