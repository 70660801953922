import RoundedButton from './Buttons/RoundedButton/RoundedButton'
import { ButtonVariants } from '../../constants'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const DemoCard = () => {
  const { t } = useTranslation('caes')
  const contactClassname = classNames(
    'w-full',
    'p-10',
    'rounded-xl',
    'shadow-lg',
    'overflow-hidden',
    'relative',
    'bg-gradient-to-tr from-black via-blue-800 to-green-800',
  )
  return (
    <div className={contactClassname}>
      <div className="relative z-10 text-center">
        <h3 className="text-2xl sm:text-4xl font-semibold text-center text-white px-6">
          {t('demoCard.title')
            .split('\n')
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </h3>
        <p className="text-xl sm:text-2xl font-semibold text-center text-white mt-6">
          {t('demoCard.text')}
        </p>
        <a
          href="https://app.hubspot.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=4957790-CAE&utm_source=LP&utm_medium=boton&utm_content=solicitar-demo"
          target="_blank"
          rel="noreferrer noopener"
        >
          <RoundedButton variant={ButtonVariants.TERTIARY} className="mt-10">
            {t('btn.contactUs')}
          </RoundedButton>
        </a>
      </div>
    </div>
  )
}

export default DemoCard
