import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export default function SecondSectionCaes() {
  const [t, i18n] = useTranslation('caes')
  const titleClassName = classNames(
    'w-full',
    'text-center',
    'lg:text-left',
    'text-2xl',
    'sm:text-4xl',
    'font-semibold',
    'text-black',
  )
  const subtitleClassName = classNames(
    'uppercase',
    'font-semibold',
    'text-blue-800',
    'sm:text-xl',
  )
  return (
    <div>
      <div className="relative z-10 flex flex-col sm:flex-row">
        <div className="basis-full sm:basis-1/2 flex justify-center items-center">
          <img
            className="w-4/5 sm:w-full"
            src={`${process.env.REACT_APP_URL_S3}/images/mock_dashboard.png`}
            alt="mainImage"
          />
        </div>

        <div className="basis-full sm:basis-1/2 p-4 sm:p-0 space-y-4 mt-10 sm:mt-0">
          <h2 className={titleClassName}>{t('secondSection.title')}</h2>
          <h3 className={subtitleClassName}>{t('secondSection.companies')}</h3>
          <p className="text-grey-900">{t('secondSection.companiesText')}</p>
        </div>
      </div>
      <div className="relative z-10 flex flex-col-reverse lg:flex-row">
        <div className="basis-full sm:basis-1/2 p-4 sm:p-0 space-y-4 mt-10 sm:mt-0">
          <h3 className={subtitleClassName}>{t('secondSection.employees')}</h3>
          <p className="text-grey-900">{t('secondSection.employeesText')}</p>
          <div className="flex flex-row space-x-2 items-center max-sm:justify-center">
            <a href="https://play.google.com/store/apps/details?id=es.inmovens.ciclogreen&hl=es&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Badge Google Play"
                src={`https://play.google.com/intl/en_us/badges/static/images/badges/${i18n.language}_badge_web_generic.png`}
                className={i18n.language === 'en' ? 'h-[60px]' : 'h-[50px]'}
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/ciclogreen-sustanaiblemobility/id1326776999?itsct=apps_box_badge&amp;itscg=30200"
              style={{
                display: 'inline-block',
                overflow: 'hidden',
                borderRadius: '13px',
              }}
              className="h-[40px]"
            >
              <img
                src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/${i18n.language}-${i18n.language}?releaseDate=1515715200`}
                alt="App Store"
                style={{
                  borderRadius: '13px',
                }}
                className="h-[40px]"
              />
            </a>
          </div>
        </div>
        <div className="basis-full sm:basis-1/2 flex justify-center items-center">
          <img
            className="h-80"
            src={`${process.env.REACT_APP_URL_S3}/images/mock_app-caes.png`}
            alt="mainImage"
          />
        </div>
      </div>
    </div>
  )
}
