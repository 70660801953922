import { ButtonVariants } from '../../constants'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import { useTranslation } from 'react-i18next'

export default function MainSectionCaes() {
  const { t } = useTranslation('caes')

  const mainSectionClassName = classNames('space-y-4', ' lg:space-y-10')
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'mt-24',
    'space-y-4',
    'text-center',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
    'relative',
    'z-10',
  )
  const bigImageClass = classNames(
    '-z-10',
    'absolute',
    'right-0',
    'w-screen',
    'h-full',
    'sm:h-auto',
    'object-cover',
  )
  const titleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'font-bold',
    'text-black',
  )
  const textlassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'text-gray-900',
    'text-xl',
  )
  return (
    <div className={mainSectionClassName}>
      <img
        className={bigImageClass}
        src={`${process.env.REACT_APP_URL_S3}/images/bg3.png`}
        alt="layerBackground"
      />
      <div className={bodyClassname}>
        <div className="basis-full lg:basis-1/2 p-4 lg:p-0 space-y-4 mt-10 lg:mt-0 flex flex-col">
          <h1
            className={titleClassName}
            style={{ lineHeight: 1.4, fontSize: 40 }}
          >
            <div className="text-blue-800">{t('mainTitle1')}</div>
            <div className="text-black">{t('mainTitle2')}</div>
          </h1>
          <h3 className={textlassName}>{t('mainText')}</h3>
          <div className="sm:space-x-10">
            <a
              href="https://landing.ciclogreen.com/es/es/es/certificados-ahorro-energetico-info"
              target="_blank"
              rel="noreferrer noopener"
            >
              <RoundedButton
                variant={ButtonVariants.PRIMARY}
                className="relative justify-start z-10 mt-10"
              >
                {t('btn.moreInfo')}
              </RoundedButton>
            </a>
            <a
              href="https://app.hubspot.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=4957790-CAE&utm_source=LP&utm_medium=boton&utm_content=solicitar-demo"
              target="_blank"
              rel="noreferrer noopener"
            >
              <RoundedButton
                variant={ButtonVariants.SECONDARY}
                className="relative justify-start z-10 mt-10"
              >
                {t('btn.askForDemo')}
              </RoundedButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
