import { ButtonVariants } from '../../constants'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import ContactFrame from 'components/Atoms/ContactFrame'
import { useTranslation } from 'react-i18next'

export default function FirstFrameCaes() {
  const { t } = useTranslation('caes')
  const titleClassName2 = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-2xl',
    'sm:text-4xl',
    'font-bold',
  )
  return (
    <ContactFrame
      className="mt-10 items-center bg-gradient-to-tr from-black via-blue-800 to-green-800"
      typeCard
      img={`${process.env.REACT_APP_URL_S3}/images/firstFrame-caes-image.png`}
      id="how_it_works"
    >
      <div className="flex flex-col space-y-6 md:w-1/2 text-white text-start items-center md:items-start">
        <div className="flex flex-col items-start space-y-6">
          <h3 className={titleClassName2}>{t('firstFrame.title')}</h3>
          <p>{t('firstFrame.text')}</p>
        </div>
        <a
          href={
            'https://landing.ciclogreen.com/es/es/es/certificados-ahorro-energetico-info'
          }
          target="_blank"
          rel="noreferrer"
        >
          <RoundedButton variant={ButtonVariants.TERTIARY}>
            {t('btn.download')}
          </RoundedButton>
        </a>
      </div>
    </ContactFrame>
  )
}
