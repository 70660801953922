import { recogniseLink } from 'utilities'

export interface RichTextProps {
  content: string
  className?: string
  highlightClassName?: string
}

const RichText = ({
  content,
  className,
  highlightClassName,
}: RichTextProps) => {
  const paragraphs = content.split('\n')

  const renderFormattedText = (text: string) => {
    const boldTextRegex = /\*\*(.*?)\*\*/g
    const parts = text.split(boldTextRegex)
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return <strong key={index}>{part}</strong>
      }
      return part
    })
  }

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <p className={className} key={index}>
          {renderFormattedText(paragraph)}
        </p>
      ))}
      {recogniseLink(content).linkText && (
        <a
          href={recogniseLink(content).linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={highlightClassName || 'text-green-800'}
        >
          {recogniseLink(content).linkText}
        </a>
      )}
    </div>
  )
}

export default RichText
