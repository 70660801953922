import classNames from 'classnames'
import { useViewport } from 'hooks'
import { Carousel } from 'react-responsive-carousel'
import { hightlightCompanies } from 'utilities'

const ClientsCarrousel = () => {
  const { width } = useViewport()
  const breakpoint = width >= 414
  const itemClassName = classNames(
    'transition',
    'ease-in-out',
    'duration-300',
    'grayscale',
    'opacity-50',
    'hover:filter-none',
    'hover:opacity-100',
  )
  const logoClassName = classNames('w-4/5', 'z-40')
  return (
    <Carousel
      infiniteLoop={true}
      showStatus={false}
      autoPlay={true}
      showThumbs={false}
      centerMode
      showIndicators={false}
      showArrows={false}
      swipeable={true}
      useKeyboardArrows={true}
      emulateTouch={true}
      centerSlidePercentage={breakpoint ? 20 : 70}
      className="relative z-10"
      swipeScrollTolerance={1}
      renderItem={(item: any) => {
        return (
          <div
            className={`${itemClassName} ${
              item.props.alt === 'iberostar' ? '!opacity-75' : ''
            }`}
          >
            {item}
          </div>
        )
      }}
    >
      {hightlightCompanies.map((company: string, index: number) => (
        <img
          className={logoClassName}
          src={`${process.env.REACT_APP_URL_S3}/images/companies/${company}.png`}
          alt={company}
          key={index}
        />
      ))}
    </Carousel>
  )
}

export default ClientsCarrousel
