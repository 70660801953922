import React from 'react'

import classNames from 'classnames'

export interface ContactFrameProps extends React.PropsWithChildren<{}> {
  className?: string
  fullWidth?: boolean
  typeCard?: boolean
  img: string
  id?: string
}

const ContactFrame = ({
  className,
  fullWidth,
  typeCard,
  children,
  img,
  id,
}: React.PropsWithChildren<ContactFrameProps>) => {
  const contactClassname = classNames(
    'flex',
    'flex-col',
    'md:flex-row',
    'justify-center',
    'w-full',
    'space-x-0',
    'md:space-x-14',
    'space-y-6',
    'md:space-y-0',
    {
      'p-6': !fullWidth,
      'px-32': fullWidth,
      'w-screen': fullWidth,
      'rounded-xl': typeCard,
      'shadow-lg': typeCard,
    },
  )
  return (
    <div className={`${contactClassname} ${className}`} id={id}>
      {children}
      <div className="relative h-80 w-80 flex items-center justify-center">
        <img src={img} alt="image" style={{ objectFit: 'cover' }} />
      </div>
    </div>
  )
}

export default ContactFrame
