import classNames from 'classnames'
import { useViewport } from 'hooks'
import { Carousel } from 'react-responsive-carousel'
import { hightlightPartners } from 'utilities'

const PartnersCarrousel = () => {
  const { width } = useViewport()
  const breakpoint = width >= 414
  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'space-y-4',
    'text-center',
    'md:text-left',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
    'mt-10',
  )
  const itemClassName = classNames(
    'transition',
    'ease-in-out',
    'duration-300',
    'grayscale',
    'opacity-20',
    'hover:filter-none',
    'hover:opacity-100',
  )
  const logoClassName = classNames('w-4/5', 'z-40')
  return (
    <div className={bodyClassname}>
      <Carousel
        infiniteLoop={true}
        showStatus={false}
        autoPlay={true}
        showThumbs={false}
        centerMode
        showIndicators={false}
        showArrows={false}
        swipeable={true}
        useKeyboardArrows={true}
        emulateTouch={true}
        centerSlidePercentage={breakpoint ? 20 : 70}
        className="relative z-10"
        swipeScrollTolerance={1}
        renderItem={(item: any) => {
          return (
            <div
              className={`${itemClassName} ${
                item.props.alt === 'iberostar' ? '!opacity-60' : ''
              }`}
            >
              {item}
            </div>
          )
        }}
      >
        {hightlightPartners.map((partner: string, index: number) => (
          <img
            className={logoClassName}
            src={`${process.env.REACT_APP_URL_S3}/images/partners/${partner}.png`}
            alt={`logo-${partner}`}
            key={index}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default PartnersCarrousel
