import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export interface BenefitCardProps {
  benefit: string
}

const BenefitCard = ({ benefit }: BenefitCardProps) => {
  const { t } = useTranslation('caes')
  const cardClassName = classNames(
    'w-full',
    'flex',
    'flex-col',
    'space-y-6',
    'items-center',
    'text-center',
    'p-4',
    'bg-white',
    'rounded-2xl',
    'shadow-lg',
    'h-full',
    'text-grey-900',
  )
  return (
    <div className={cardClassName}>
      <img
        src={`${process.env.REACT_APP_URL_S3}/images/${benefit}.png`}
        alt={`benefit-${benefit}`}
        className="w-32"
      />
      <h3 className="font-semibold text-xl">
        {t(`benefits.${benefit}`)
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
      </h3>
      <div className="text-gray-800 text-center space-y-4 flex-1">
        {t(`benefits.${benefit}Text`)
          .split('\n')
          .map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
      </div>
    </div>
  )
}

export default BenefitCard
