import classNames from 'classnames'
import { TestimonialProps } from 'models/Client'

const Testimonial = ({ client, className, mesagge }: TestimonialProps) => {
  const testimonialClassname = classNames(
    'flex',
    'flex-col',
    'w-100',
    'p-6',
    'rounded-xl',
    'shadow-lg',
    'bg-white',
    'm-5',
    'text-grey-900',
  )
  return (
    <div className={`${className} ${testimonialClassname}`}>
      {mesagge}
      <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 items-center mt-5">
        <div className="h-20 w-20 relative rounded-full">
          <img
            src={`${process.env.REACT_APP_URL_S3}/images/companies/clients/${client.avatar}.png`}
            alt={client.name}
            style={{ objectFit: 'cover' }}
            className="
            rounded-full"
          />
        </div>
        <div className="flex flex-col items-center md:items-start">
          <p className="text-blue-800 text-center md:text-start font-bold">
            <span className="uppercase">{client.name}</span>
            <span className="text-black font-semibold">{` ${client.position}`}</span>
          </p>
          <div className="relative w-32 h-16 p-0">
            <img
              src={`${process.env.REACT_APP_URL_S3}/images/companies/${client.companyLogo}.png`}
              alt={client.company}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
